import React, { useEffect, useState } from 'react';
import partnerLogo from '../../assets/partnerLogo/apple-logo-2400x2400-20220512-1.webp'
// const partners = [
//   { name: 'ABN AMRO', imgSrc: 'path/to/abn-amro-logo.png' },
//   // { name: 'Accenture', imgSrc: 'https://logos-world.net/wp-content/uploads/2020/07/Accenture-Logo.png' },
//   { name: 'Adobe', imgSrc: 'path/to/adobe-logo.png' },
//   { name: 'Times of India', imgSrc: 'path/to/timesofindia-logo.png' },
//   { name: 'Bharti', imgSrc: 'path/to/bharti-logo.png' },
//   { name: 'Sony', imgSrc: 'path/to/sony-logo.png' },
//   { name: 'Microsoft', imgSrc: 'path/to/microsoft-logo.png' },
//   { name: 'Nokia', imgSrc: 'path/to/nokia-logo.png' },
//   { name: 'IBM', imgSrc: 'path/to/ibm-logo.png' },
//   { name: 'Pepsi', imgSrc: 'path/to/pepsi-logo.png' },
//   { name: 'HP', imgSrc: 'path/to/hp-logo.png' },
//   { name: 'NIIT', imgSrc: 'path/to/niit-logo.png' },
//   // { name: 'Philips', imgSrc: 'path/to/philips-logo.png' },
//   // { name: 'Barclays', imgSrc: 'path/to/barclays-logo.png' },
//   // { name: 'Deutsche Bank', imgSrc: 'path/to/deutschebank-logo.png' },

// ];

const PartnersSection = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Set up a counter incrementing to 100
    const target = 100;
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < target) {
          return prevCount + 1;
        } else {
          clearInterval(interval);
          return target;
        }
      });
    }, 50); // Speed of counting (in ms)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center min-h-[75%] bg-gray-100">

      <div className="py-12 px-8">
        <h2 className="text-center text-3xl font-bold mb-8">
          We Have More Than <span className="text-yellow-500 text-4xl">{count === 100 ? "100+" : count} Global Partners</span>
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-6 px-8">
          {/* {partners.map((partner, index) => (
          <div key={index} className="flex flex-col justify-center items-center p-4 bg-white shadow-md rounded-lg">
            <img
               src='https://logos-world.net/wp-content/uploads/2020/07/Accenture-Logo.png'            className="max-h-12 object-contain" 
            />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">{partner.name}</span> 
            </h2>
          </div>
        ))} */}
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105'>
            <img src='https://logos-world.net/wp-content/uploads/2020/07/Accenture-Logo.png' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Accenture</span>
            </h2>

          </div>
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105'>
            <img src='https://1000logos.net/wp-content/uploads/2021/05/Sony-logo.png' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Sony</span>
            </h2>

          </div>
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105'>
            <img src='https://logowik.com/content/uploads/images/bharti9488.logowik.com.webp' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Bharti</span>
            </h2>

          </div>
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105'>
            <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Microsoft</span>
            </h2>

          </div>
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105 '>
            <img src='https://upload.wikimedia.org/wikipedia/commons/6/68/Pepsi_2023.svg' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Pepsi</span>
            </h2>

          </div>
          <div className='flex flex-col justify-center item-center p-4 by-white shadow-md round-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105'>
            <img src='https://download.logo.wine/logo/Barclays/Barclays-Logo.wine.png' />
            <h2 className="text-center text-xl font-bold mt-4">
              <span className="text-slate-900">Barclay</span>
            </h2>

          </div>
        </div>
      </div>

    </div>

  );
};

export default PartnersSection;
