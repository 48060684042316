import React from 'react'
import { useNavigate } from 'react-router-dom';

const BreadCrumb = ({breadcrumbItems,}) => {
    const navigate = useNavigate();
  return (
    <>
      {/* Breadcrumb */}
      <nav className=" mt-2 py-4 px-4 sm:px-6 bg-gray-300 text-gray-700">
        <ol className="flex space-x-2 justify-center text-sm sm:text-base">
          {breadcrumbItems.map((item, index) => (
            <li key={index}>
              <button
                onClick={() => navigate(item.path)}
                className="hover:underline font-bold text-lg text-yellow-600"
              >
                {item.label}
              </button>
              {index < breadcrumbItems.length - 1 && (
                <span className="px-2">/</span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    
    </>
  )
}

export default BreadCrumb
