// achieverData.js (Sample Achiever Data)
export const achievers = [
    {
      id: 1,
      name: 'John Doe',
      achievement: 'Awarded Best Innovator of the Year 2023',
      imageUrl: 'https://via.placeholder.com/150', // Replace with actual image link
    },
    {
      id: 2,
      name: 'Jane Smith',
      achievement: 'Top Researcher in AI and Machine Learning',
      imageUrl: 'https://via.placeholder.com/150', // Replace with actual image link
    },
    {
      id: 3,
      name: 'Alice Johnson',
      achievement: 'CEO of the Year 2022 in Tech Industry',
      imageUrl: 'https://via.placeholder.com/150', // Replace with actual image link
    },
  ];
  