// import React from 'react'
// import bg from '../../assets/image/heroImage2.jpg'


// export default function HeroCarouselItem() {

//   return (

//     <div>


//       <div class="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${bg})` }} >

//   <div class="absolute inset-0 bg-black opacity-50"></div> 
//   <div class="relative flex flex-col justify-center items-center h-full text-center px-4 md:px-8">
//     <h1 class="text-white text-4xl md:text-6xl font-bold mb-4">Your Hero Title</h1>
//     <p class="text-white text-lg md:text-xl mb-6">This is some descriptive text for the hero section. You can customize it as needed.</p>
//     <button class="px-6 py-3 bg-sky-500 hover:bg-sky-700 text-white text-lg font-semibold rounded-md">
//       Get Started
//     </button>
//   </div>
// </div>
//     </div>


//   );
// }

import React from 'react';
import bg from '../../assets/image/heroImage2.jpg'; // Make sure to 


// Replace with your actual image path

export default function HeroCarouselItem() {
  return (
    // <section className="relative w-full h-[60vh] sm:h-[70vh] lg:h-[80vh]">
    //   {/* Background image with blur */}
    //   <img
    //     src={bg}
    //     alt="Background"
    //     className="absolute inset-0 w-full h-full object-cover "
    //   />
    //   {/* Gradient overlay */}
    //   <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-black opacity-40"></div>

    //   {/* Content Section */}
    //   <div className="relative z-10 flex items-center justify-center h-full">
    //     <div className="text-center px-6">
    //       <h1 className="text-white text-4xl sm:text-5xl lg:text-6xl font-extrabold underline">
    //         Your Hero Title
    //       </h1>
    //       <p className="text-white mt-4 text-sm sm:text-lg lg:text-xl">
    //         This is some descriptive text for the hero section. You can customize it as needed.
    //       </p>
    //       <button className="mt-4 px-6 py-3 bg-sky-500 hover:bg-sky-700 text-white text-lg font-semibold rounded-md transition-all duration-300">
    //         Get Started
    //       </button>
    //     </div>
    //   </div>
    // </section>
    <>
      {/* <section className="relative w-full h-[60vh] sm:h-[70vh] lg:h-[70vh]">
        <img
          src={bg}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover blur-md"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-slate-700 opacity-5"></div>
        <div className="relative z-10 flex items-center justify-center h-full t">
          <div className="text-center px-6">
            <h1 className="text-slate-900 text-4xl sm:text-5xl lg:text-6xl font-extrabold underline">About Us</h1>
            <p className="text-white mt-4 text-sm sm:text-lg lg:text-xl">
              Learn more about our mission and values
            </p>
          </div>
        </div>
      </section> */}
      <section className="relative w-full h-[60vh] sm:h-[70vh] lg:h-[80vh] overflow-hidden">
        <img
          src={bg}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover "
        />
        <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-slate-700 opacity-40"></div>

        <div className="relative z-10 flex items-center justify-center h-full">
          <div className="text-center px-4 sm:px-6 lg:px-8">
            <h1 className="text-white text-4xl sm:text-5xl lg:text-6xl font-extrabold underline">
              Welcome
            </h1>
            <p className="text-white mt-4 text-sm sm:text-lg lg:text-xl">
              Join us our Alumni
            </p>
            <button className="mt-4 px-6 py-3 bg-sky-500 hover:bg-sky-700 text-white text-lg font-semibold rounded-md transition-all duration-300">
              Get Started
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

