import React from 'react';


import missionImg from "../../assets/image/heroImage1.jpg";
import NewsletterSection from '../../component/newsletterSection/NewsletterSection';

import SelectionNumber from '../../component/selectionNumber/SelectionNumber';
import Layout from '../../layout/Layout';
import CompnentHero from '../../component/compnentHero/CompnentHero';
import BreadCrumb from '../../component/breadCrumb/BreadCrumb';
import invoimg from '../../assets//innovt.png'
import growthimg from '../../assets/growth.png'
import chooseimg from '../../assets/choose.png'
import aboutlogo from '../../assets/Stl2.png'
const About = () => {


  const about={
    title:"ABOUT",
    content: "Know to more Mission and  target",
    logo : aboutlogo
}


  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'About Us', path: '/about' }
  ];

  return (
    <div className="bg-gray-50">
      <Layout>
      <CompnentHero title={about.title} content={about.content} heroImg={about.logo}/>
      <BreadCrumb breadcrumbItems={breadcrumbItems} />
      {/* Content Section */}
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
          {/* Image and Text Block 1 */}
          <div className="flex flex-col items-center bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105">
            <img
              src={missionImg}
              alt="Our Mission"
              className="w-full h-48 object-cover rounded-lg mb-4 transform hover:rotate-3 hover:scale-110 transition-all duration-300"
            />
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
              Our Mission
            </h3>
            <p className="text-gray-600 text-base sm:text-lg">
              We are committed to delivering the best services to ensure customer satisfaction and drive innovation.
            </p>
          </div>

          {/* Image and Text Block 2 */}
          <div className="flex flex-col items-center bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105">
            <img
              src={invoimg}
              alt="Innovation"
              className="w-full h-48 object-cover rounded-lg mb-4 transform hover:rotate-3 hover:scale-110 transition-all duration-300"
            />
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
              Innovation
            </h3>
            <p className="text-gray-600 text-base sm:text-lg">
              We embrace cutting-edge technology and innovative ideas to stay ahead in the industry.
            </p>
          </div>

          {/* Image and Text Block 3 */}
          <div className="flex flex-col items-center bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105">
            <img
              src={growthimg}
              alt="Growth"
              className="w-full h-48 object-cover rounded-lg mb-4 transform hover:rotate-3 hover:scale-110 transition-all duration-300"
            />
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
              Growth
            </h3>
            <p className="text-gray-600 text-base sm:text-lg">
              Our mission is to expand globally while maintaining our core values.
            </p>
          </div>
        </div>
      </section>

    {/* Second Row of Content */}
<section className="py-6 px-4 sm:px-6 lg:px-8">
{/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8 mt-12 place-items-center"> */}
   <div className='flex justify-center'>
    <div className="flex items-center">
      <div>
        <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800">Why Choose Us?</h3>
        <p className="mt-4 text-gray-600 text-sm sm:text-base lg:text-lg">
          We are committed to providing unparalleled service and creating meaningful connections with our clients.
        </p>
        <ul className="mt-6 space-y-4 text-gray-600 text-sm sm:text-base lg:text-lg">
          <li className="flex items-center space-x-2">
            <span className="bg-green-500 w-3 h-3 rounded-full inline-block"></span>
            <p>Customer-focused solutions</p>
          </li>
          <li className="flex items-center space-x-2">
            <span className="bg-green-500 w-3 h-3 rounded-full inline-block"></span>
            <p>Industry-leading innovations</p>
          </li>
          <li className="flex items-center space-x-2">
            <span className="bg-green-500 w-3 h-3 rounded-full inline-block"></span>
            <p>Proven track record of success</p>
          </li>
        </ul>
      </div>
    </div>
    <img
      src={chooseimg}
      alt="Why Choose Us"
      className="rounded-lg shadow-lg object-cover"
    />
  </div>
</section>
<SelectionNumber/>
<NewsletterSection/>
<br></br>
</Layout>


    </div>
  );
};

export default About;


