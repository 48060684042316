// import React, { useState, useEffect } from 'react';
// import { Modal, Box, Button } from '@mui/material';

// const SimpleModal = ({ open, handleClose }) => {
//   return (
//     <Modal open={open} onClose={handleClose}>
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           borderRadius: '16px',
//           boxShadow: 24,
//           p: 4,
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           textAlign: 'center',
//         }}
//       >
//         <h2 style={{ color: '#007BFF', fontFamily: 'Arial, sans-serif' }}>Welcome to the Site!</h2>
//         <p style={{ marginBottom: '20px', color: '#555' }}>
//           Hello, welcome to our college Alumni
//         </p>
//         <p style={{ marginBottom: '20px', color: '#555' }}>
//           Please register here
//         </p>
//         <Button
//           variant="contained"
//           onClick={handleClose}
//           sx={{
//             bgcolor: '#007BFF',
//             color: '#fff',
//             '&:hover': {
//               bgcolor: '#0056b3',
//             },
//             borderRadius: '20px',
//             padding: '10px 20px',
//           }}
//         >
//           Register
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default SimpleModal;
import React, { useState, useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SimpleModal = ({ open, handleClose }) => {
  const navigate= useNavigate();
  const handleNavigate=()=>{
    navigate('/register')
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h2 style={{ color: '#007BFF', fontFamily: 'Arial, sans-serif' }}>Welcome to the Site!</h2>
        <p style={{ marginBottom: '20px', color: '#555' }}>
          Hello, welcome to our college Alumni
        </p>
        <p style={{ marginBottom: '20px', color: '#555' }}>
          Please register here
        </p>
        <Button
          variant="contained"
          onClick={handleNavigate}
          sx={{
            bgcolor: '#007BFF',
            color: '#fff',
            '&:hover': {
              bgcolor: '#0056b3',
            },
            borderRadius: '20px',
            padding: '10px 20px',
          }}
        >
          Register
        </Button>
      </Box>
    </Modal>
  );
};

export default SimpleModal;