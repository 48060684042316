import React from 'react'

import CompnentHero from '../../component/compnentHero/CompnentHero'
import contactlogo from '../../assets/Stl3.png'

import missionImg from "../../assets/image/heroImage1.jpg";
import ContactSection from '../../component/contactSection/ContactSection';
import Layout from '../../layout/Layout';
import BreadCrumb from '../../component/breadCrumb/BreadCrumb';

const Contact = () => {
  const testimonal={
    title:"CONTACT",
    content: "Please register to more Updates",
    logo : contactlogo
}

const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Contact', path: '/contact' }
  ];
  return (
    <> 
     
     <Layout>
     <CompnentHero title={testimonal.title} content={testimonal.content} heroImg={testimonal.logo}/>
       <BreadCrumb breadcrumbItems={breadcrumbItems} />
       <ContactSection/>
     </Layout>
       
       </>
       
  )
}

export default Contact
