import React, { useState, useEffect } from 'react';
import { BrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AllRoutes } from './allRoutes/AllRoutes';
import SimpleModal from './component/Modal/SimpleModal';

function App() {
  // const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal state

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsModalOpen(true);
  //   }, 2000);


  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className="App">
      {/* <SimpleModal open={isModalOpen} handleClose={handleCloseModal} /> */}
      <RouterProvider router={AllRoutes} />

    </div>


  );
}

export default App;
