
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import CompanyLogo from '../../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const LoginPage = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for password visibility

    const backButton = () => {
        navigate('/');
    };

    const HandleChangeRoute=()=>{
        navigate('/Adminuser')
    }
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    });

    const handleLogin = async (values, { setSubmitting }) => {
        console.log(values)
        setSubmitting(true);
    
        try {
            const response = await fetch('https://college-alumni.callibry.com:8080/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: values.email,
                    password: values.password,
                }),
            });
    
            const data = await response.json();
            // console.log('Response:', response); 
            // console.log('Data:', data);          
    
            if (response.ok) {
            
                if(data.status==="1"){
                    toast.success(data.message)
                    setIsLoggedIn(true);  
                    // localStorage.setItem('isLoggedIn', 'true');
                }
                
                
            } else {
                toast.error(data.message)
                // alert('Login failed: ' + (data.message || 'Unknown error occurred.'));
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login failed. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="flex h-screen">
            {/* Left Side Image Section */}
            <div className="hidden md:flex w-1/2 bg-gradient-to-br from-blue-500 to-purple-600 justify-center items-center">
                <img
                    src={CompanyLogo}
                    alt="Alumni Welcome"
                    className="h-64 w-65 rounded-lg shadow-lg"
                />
            </div>

            {/* Right Side Section */}'
            <ToastContainer/>
            
            <div className="w-full md:w-1/2 flex justify-center items-center bg-gray-100">
                {!isLoggedIn ? (
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        {({ isSubmitting }) => (
                            <Form className="w-3/4 max-w-md">
                                <h2 className="text-3xl font-semibold text-gray-800 mb-6">Admin Login</h2>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-600">Email Address</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="w-full p-3 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                                        placeholder="Enter your email"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-600 mt-1" />
                                </div>

                                <div className="mb-6 relative"> {/* Added relative for positioning the icon */}
                                    <label htmlFor="password" className="block text-gray-600">Password</label>
                                    <Field
                                        type={showPassword ? "text" : "password"} // Toggle between text and password
                                        name="password"
                                        id="password"
                                        className="w-full p-3 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
                                        placeholder="Enter your password"
                                    />
                                    <ErrorMessage name="password" component="div" className="text-red-600 mt-1" />
                                    {/* Show/Hide Password Icon */}
                                    <span
                                        className="absolute right-3 top-10 cursor-pointer"
                                        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                                    >
                                        {showPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 12a9 9 0 0117.293-1.293A9 9 0 113 12zm3.429 3.429a5.5 5.5 0 008.142-8.142M9.432 9.432a5.5 5.5 0 008.142 8.142M15 9h-2v4h2m0 0v4m-2-4h-2m0 0h-2m2 0h2" /></svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12l4.4-4.4M3 3l18 18M9.5 10.5l2 2M12 4a8.5 8.5 0 1016 0 8.5 8.5 0 00-16 0zm6.5 3.5H15l-3-3m0 0h-.01" /></svg>
                                        )}
                                    </span>
                                </div>

                                <button
                                    type="submit"
                                    className={`w-full p-3 text-white rounded-lg ${isSubmitting ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700'
                                        } transition duration-300`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Logging in...' : 'Login'}
                                </button>

                                <div className="mt-4 text-center flex justify-between">
                                    <NavLink to='/forgotpassword'>
                                        <a href="/forgot-password" className="text-purple-600 hover:underline">
                                            Forgot password?
                                        </a>
                                    </NavLink>
                                    <a href="/" className='text-purple-600 hover:underline'>Back to Home</a>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div className="w-3/4 max-w-md text-center">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Welcome to the Alumni Dashboard</h2>
                        <p className="text-gray-600 mb-6">
                            You have successfully logged in. Explore the alumni content and resources available for you.
                        </p>
                        <button
                            onClick={HandleChangeRoute}
                            className="bg-purple-600 text-white p-3 rounded-lg hover:bg-purple-700 transition duration-300"
                        >
                            Go to Dashboard
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoginPage;

