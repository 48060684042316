import React from 'react'

const ContactSection = () => {
  return (
    <>
      <div className="bg-gray-100 py-10">
        {/* Main container */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Title */}
          <div className="text-center mb-10">
            <h2 className="text-3xl font-extrabold text-gray-900">
              Contact Us & Alumni Network
            </h2>
            <p className="mt-3 text-lg text-gray-600">
              Get in touch with us or connect with our alumni.
            </p>
          </div>

          {/* Contact & Alumni Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-12">
            {/* Contact Us Section */}
            <div className="bg-white  shadow-lg rounded-lg">
              <h3 className="text-xl font-bold mb-4 text-white bg-blue-500 text-center">Contact Us</h3>
              <div className=' p-8'>
                <p className="text-gray-600 mb-6">
                  Reach out to us for any queries or assistance.
                </p>
                <div>
                  <p className="font-semibold">Email:</p>
                  <p>info@startelelogic.com</p>
                </div>
                <div className="mt-4">
                  <p className="font-semibold">Phone:</p>
                  <p>+1 (555) 123-4567</p>
                </div>
                <div className="mt-4">
                  <p className="font-semibold">Address:</p>
                  <p>F 23, F Block, Sector 11, Noida, Uttar Pradesh 201301</p>
                </div>
              </div>

            </div>

            {/* Alumni Section */}
            <div className="bg-white  shadow-lg rounded-lg">
              <h3 className="text-xl font-bold mb-4 text-white bg-blue-500 text-center">Alumni Network</h3>
              <div className='p-8'>
                <p className="text-gray-600 mb-6">
                  Connect with our alumni spread across the globe.
                </p>
                <div>
                  <p className="font-semibold">Global Network:</p>
                  <p>Over 5000 alumni in 60+ countries</p>
                </div>
                <div className="mt-4">
                  <p className="font-semibold">LinkedIn Group:</p>
                  <a
                    href="https://www.linkedin.com/company/startelelogic/mycompany/verification/" target='_blank'
                    className="text-blue-500 hover:underline"
                  >
                    Join our LinkedIn group
                  </a>
                </div>
                <div className="mt-4">
                  <p className="font-semibold">Alumni Events:</p>
                  <p>Check out our upcoming events and reunions.</p>
                </div>
              </div>
            </div>

          </div>

          {/* Contact Form */}
          <div className="bg-white shadow-lg rounded-lg mb-12">
            <h3 className="text-xl font-bold mb-4 text-white bg-blue-500 text-center">Send Up Message</h3>
            <form>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 p-8">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    placeholder="Your name"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    placeholder="Your email"
                  />
                </div>
                <div className="sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea
                    rows="4"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    placeholder="Your message"
                  ></textarea>
                </div>
                {/* <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="mt-4 w-full bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Submit
                </button>
              </div> */}

              </div>
              <div className="flex justify-center items-center p-4">
                <button type='submit' className='px-40 py-2 bg-blue-600  rounded-md text-white' >Submit </button>
              </div>
            </form>
          </div>

          {/* Alumni Testimonials */}


          {/* FAQ Section */}

          <div className="mt-12">
            <h3 className="text-xl font-bold mb-4">Our Locations</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              {/* Company Location */}
              <div className="bg-white  shadow-lg rounded-lg">

                <h3 className="text-xl font-bold mb-4 text-white bg-blue-500 text-center">Headquarters</h3>
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.99025074955!2d77.32928067597068!3d28.600069285553335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sstartelelogic!5e0!3m2!1sen!2sin!4v1715832562116!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen=""
                loading="lazy"
                title="Alumni Location"
                className="rounded-lg"
                style={{padding:'8px'}}
              ></iframe>
              </div>

              {/* Alumni Location */}
              <div className="bg-white shadow-lg rounded-lg">

                <h3 className="text-xl font-bold mb-4 text-white bg-blue-500 text-center"> Alumni Meet-Up Location</h3>
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.99025074955!2d77.32928067597068!3d28.600069285553335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sstartelelogic!5e0!3m2!1sen!2sin!4v1715832562116!5m2!1sen!2sin"
                width="100%"
                height="300"
                allowFullScreen=""
                loading="lazy"
                title="Alumni Location"
                className="rounded-lg"
                style={{padding:'8px'}}
              ></iframe>
                {/* <iframe className='map-section'
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.99025074955!2d77.32928067597068!3d28.600069285553335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sstartelelogic!5e0!3m2!1sen!2sin!4v1715832562116!5m2!1sen!2sin"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactSection
