// import React from 'react';
// import newsLetterBg from '../../assets/image/heroImage3.jpg'


// const NewsletterSection = () => {
//   return (
//     <div
//       className="relative h-[500px] flex justify-center items-center bg-cover bg-center bg-no-repeat"
//       style={{
//         backgroundImage: `url(${newsLetterBg})`, // Replace with your image URL
//       }}
//     >
//       {/* Gradient Overlay */}
//       <div className="absolute inset-0  bg-black opacity-50" ></div>

//       {/* Newsletter Content */}
//       <div className="relative z-10 p-8 max-w-3xl mx-auto text-center text-white">
//         <h2 className="text-5xl font-extrabold mb-4">
//           Join Our Creative Community
//         </h2>
//         <p className="text-lg mb-10 max-w-xl mx-auto">
//           Subscribe to our newsletter and get the latest updates, tips, and
//           exclusive offers delivered to your inbox.
//         </p>

//         {/* Input and Button */}
//         <div className="flex items-center justify-center space-x-4 backdrop-blur-md bg-white bg-opacity-20 p-4 rounded-full shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
//           <input
//             type="email"
//             placeholder="Enter your email"
//             className="p-4 rounded-full w-72 text-gray-900 focus:outline-none focus:ring-2 focus:ring-purple-400 bg-white bg-opacity-70 backdrop-blur-md transition-all duration-300 ease-in-out transform hover:scale-105"
//           />
//           <button className="px-6 py-4 bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-bold rounded-full shadow-md hover:shadow-xl hover:bg-gradient-to-l transition duration-300 transform hover:scale-110">
//             Subscribe
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewsletterSection;
// ;
import React from 'react';
import newsLetterBg from '../../assets/image/heroImage3.jpg';

const NewsletterSection = () => {
  return (
    <div
      className="relative flex justify-center items-center bg-cover bg-center bg-no-repeat h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] xl:h-[700px] overflow-hidden"
      style={{
        backgroundImage: `url(${newsLetterBg})`,
      }}
    >
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Newsletter Content */}
      <div className="relative z-10 p-4 sm:p-8 max-w-3xl mx-auto text-center text-white">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4">
          Join Our Creative Community
        </h2>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl mb-6 sm:mb-10 max-w-xl mx-auto">
          Subscribe to our newsletter and get the latest updates, tips, and
          exclusive offers delivered to your inbox.
        </p>

        {/* Input and Button */}
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 backdrop-blur-md bg-white bg-opacity-20 p-3 sm:p-4 rounded-full shadow-lg hover:shadow-2xl transition duration-300 ease-in-out">
          <input
            type="email"
            placeholder="Enter your email"
            className="p-3 sm:p-4 rounded-full w-full sm:w-64 md:w-72 lg:w-80 text-gray-900 focus:outline-none focus:ring-2 focus:ring-purple-400 bg-white bg-opacity-70 backdrop-blur-md transition-all duration-300 ease-in-out transform hover:scale-105"
          />
          <button className="px-4 sm:px-6 py-3 sm:py-4 bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-bold rounded-full shadow-md hover:shadow-xl hover:bg-gradient-to-l transition duration-300 transform hover:scale-110">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection;

