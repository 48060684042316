import React from 'react'



const CompnentHero = ({title,content,heroImg}) => {
  return (
    <>
         {/* Hero Section */}
      <section className="relative w-full h-[60vh] sm:h-[70vh] lg:h-[70vh]">
        <img
          src={heroImg}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover blur-md"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-slate-400 to-slate-700 opacity-5"></div>
        <div className="relative z-10 flex items-center justify-center h-full t">
          <div className="text-center px-6">
            <h1 className="text-slate-900 text-4xl sm:text-5xl lg:text-6xl font-extrabold underline">{title}</h1>
            <p className="text-white mt-4 text-sm sm:text-lg lg:text-xl">
             {content}
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default CompnentHero