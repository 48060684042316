import React from 'react'
import ConstructionPage from '../constructionPage/ConstructionPage'
import CompnentHero from '../../component/compnentHero/CompnentHero'
import BreadCrumb from '../../component/breadCrumb/BreadCrumb'
import missionImg from "../../assets/image/heroImage1.jpg";
import registerlogo from '../../assets/stl.png'

import Layout from '../../layout/Layout';
import RegistrationForm from '../../component/registerForm/RegistrationForm';

const Register = () => {

    const register={
        title:"ALUMNI REGISTERATION",
        content: "Please register to more Updates",
        logo : registerlogo
    }

    const breadcrumbItems = [
        { label: 'Home', path: '/' },
        { label: 'Register', path: '/register' }
      ];
  return (
    <>     
        <Layout>
          <CompnentHero title={register.title} content={register.content} heroImg={register.logo}/>
          <BreadCrumb breadcrumbItems={breadcrumbItems} />
          <RegistrationForm/>
          </Layout>   
         
       
    </>
   
  )
}

export default Register