import React from 'react'
import Layout from '../../../layout/Layout'

import AchieverDetailSection from '../../../component/achieverDetail/AchieverDetail'
import AchieverSection from '../../../component/achieverSection/AchieverSection'
import CompnentHero from '../../../component/compnentHero/CompnentHero'
import BreadCrumb from '../../../component/breadCrumb/BreadCrumb'
import missionImg from "../../../assets/image/heroImage2.jpg";  

const AchieverDetail = () => {

    const register={
        title:"Registe",
        content: "Please register to more Updates"
    }

    const breadcrumbItems = [
        { label: 'Home', path: '/' },
        { label: 'Achievers', path: '/achiever/:id' }
      ];
  return (
    <div>
        <Layout>
        <CompnentHero title={register.title} content={register.content} heroImg={missionImg}/>
        <BreadCrumb breadcrumbItems={breadcrumbItems} />
            <AchieverDetailSection/>
            <AchieverSection/>

        </Layout>
      
    </div>
  )
}

export default AchieverDetail
