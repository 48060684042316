import React from 'react'

import CompnentHero from '../../component/compnentHero/CompnentHero'
import BreadCrumb from '../../component/breadCrumb/BreadCrumb'
import missionImg from "../../assets/image/heroImage1.jpg";
import NavBar from '../../component/navbar/NavBar';
import Banner from '../../component/banner/Banner';
import ConstructionPage from '../constructionPage/ConstructionPage';
import Footer from '../../component/footer/Footer';
import Layout from '../../layout/Layout';


const Testimonal = () => {
  const testimonal={
    title:"Testimonal",
    content: "Please register to more Updates"
}

const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Testimonal', path: '/testimonal' }
  ];
  return (
   <>
  
  
     <Layout>
        
     <CompnentHero title={testimonal.title} content={testimonal.content} heroImg={missionImg}/>
         <BreadCrumb breadcrumbItems={breadcrumbItems} />
         <ConstructionPage/>
     </Layout>
   </>
  )
}

export default Testimonal
