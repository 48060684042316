import { NavLink } from 'react-router-dom';
import newlogo from '../../assets/newLogo.png'
import lg from '../../assets/Screenshot (52).png'


const Footer= () => {
  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Testimonal', href: '/testimonal' },
    { name: 'Contact', href: '/contact' },
    {name :'Register' ,href:'/register'},
    {name :'Placement' ,href:'/placement'}
  ]
    return (
        <>
            <div className="  bg-gradient-to-br from-black via-blue-1000 to-blue-900 text-white pt-8">
       
        <div className="flex flex-wrap justify-around p-8">

          <div className="flex flex-col gap-4 p-4 w-60">
            <div className="flex justify-center bg-white">
              <img src={lg} className="" />
            </div>
            <div className="text-center">startelelogic pvt limited</div>
            <div className='flex justify-center'>
              <button type="submit" className="py-2 px-4 bg-red-500 text-white rounded cursor-pointer">About college!</button>
            </div>
          </div>

          <div className="flex flex-col gap-4 p-4">
            <div className="text-orange-500 font-semibold">Contact-info</div>
            <div>
              <div className="text-orange-500">Location</div>
              <div>F 23, F Block, Sector 11, Noida,<br>
              </br> Uttar Pradesh 201301</div>
            </div>
            <div>
              <div className="text-orange-500">Phone number</div>
              <div>+91-120-433-3335</div>
              <div> phone Number</div>
            </div>
            <div>
              <div className="text-orange-500">Email:</div>
              <div>info@startelelogic.com</div>
            </div>
            <div className="flex gap-1">
              <a href="https://www.facebook.com/StarTeleLogic/" target="_blank" rel="noopener noreferrer">
                <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-64.png" className="h-5 w-5" alt="Facebook" />
              </a>

              <a href="https://www.instagram.com/startelelogic/" target="_blank" rel="noopener noreferrer"><img src="https://cdn1.iconfinder.com/data/icons/social-rounded-2/32/instagram-64.png" className="h-5 w-5" /></a>
              <a href="https://x.com/startelelogic?lang=en"target="_blank" rel="noopener noreferrer"><img src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter3_colored_svg-64.png" className="h-5 w-5" /></a>
              <a href="https://www.youtube.com/channel/UCaVufyKNouVaczs2gyoYTWA" target="_blank" rel="noopener noreferrer"><img src="https://cdn0.iconfinder.com/data/icons/social-flat-rounded-rects/512/youtube-64.png" className="h-5 w-5" /></a>
              <a href="https://www.linkedin.com/company/startelelogic/" target="_blank" rel="noopener noreferrer"><img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/square-linkedin-64.png" className="h-5 w-5" /></a>
            </div>
          </div>

          <div className="flex flex-col gap-4 p-4">
            <div className="font-semibold text-orange-500">Menu</div>
            <div className="flex flex-col gap-2">
              {navigation.map((item ,index)=>(
                <div className="flex gap-1" hey={index}>
                
                <div>
                 
                  <NavLink to={item.href}>
                    <button>{item.name}</button>
                  </NavLink>
                </div>
              </div>
              ))}                        
            </div>
          </div>

          <div className="flex flex-col  gap-4 p-4">
            <div className="font-semibold text-orange-500">Quick Links</div>
            <div className="flex flex-col gap-2">
              <div>Fee Structure</div>
              <div>Faculty</div>
              <div>Schemes and Syllabus</div>
              <div>Admission Procedure</div>
              <div>Our Recruiters</div>
              <div>Event & Updates</div>
              <div>Gallery</div>
              <div>Grievances</div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center p-8 text-center">
          © Copyright 2024 startelelogic. All Rights Reserved.
        </div>
      </div>
        </>
    )
}
export default Footer;