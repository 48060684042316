import React from 'react';
import { FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SideBar = ({ onSwitchChange }) => {
    const navigate=useNavigate()
  const handleLogout = () => {
    // Implement logout functionality
    navigate('/Login')

  };
  const handleChange = () => {
    // Implement logout functionality
    navigate('/adminsetting')

  };

  return (
    <div className="bg-blue-600 text-white h-screen w-64 p-6 flex flex-col">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">Admin Panel</h2>
        <FaCog className="text-white hover:text-gray-300 cursor-pointer"  onClick={handleChange}/>
      </div>
      <div className="flex flex-col space-y-3 mb-6">
        <button
          className="bg-blue-700 hover:bg-blue-800 transition-colors py-2 px-4 rounded"
          onClick={() => onSwitchChange('adminUser')}
        >
          Admin User
        </button>
        <button
          className="bg-blue-700 hover:bg-blue-800 transition-colors py-2 px-4 rounded"
          onClick={() => onSwitchChange('anotherSection')}
        >
          Admin data
        </button>
        <button
          className="bg-blue-700 hover:bg-blue-800 transition-colors py-2 px-4 rounded"
          onClick={() => onSwitchChange('thirdSection')}
        >
          Third Section
        </button>
      </div>
      <div className="mt-auto flex justify-center">
        <button
          onClick={handleLogout}
          className="bg-red-600 hover:bg-red-700 transition-colors py-2 px-4 rounded"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default SideBar;
