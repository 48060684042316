
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const AdminUserDetails = () => {
  const [sendCheckedClicked, setSendCheckedClicked] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [isSending, setIsSending] = useState('false');
  const [loadingState, setLoadingState] = useState({});
  const [isVisible , setIsVisible]=useState(false)



  useEffect(() => {
    fetch("https://college-alumni.callibry.com:8080/get-all-users")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const openEditModal = (user) => {
    setEditUser(user);
  };

  const closeEditModal = () => {
    setEditUser(null);
  };

  const handleSave = () => {
    closeEditModal();
  };
  // Function to handle sending email
  const handleSendChecked = async () => {
    if (selectedUsers.size === 0) return;
    setSendCheckedClicked(true);

    for (let email of selectedUsers) {
      await handlecheck(email);
    }

    setSendCheckedClicked(false); // Reset after sending emails
  };

  const handlecheck = async (email) => {
    if (sendCheckedClicked) return; // If SendChecked is clicked, prevent individual sends

    setLoadingState((prevState) => ({ ...prevState, [email]: true }));

    try {
      const url = `https://college-alumni.callibry.com:8080/invitation-email/${email}`;
      const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });

      if (response.ok) {
        const data = await response.json();
        if (data.status === "0") {
          toast.error(data.message || "Error sending mail");
        } else if (data.status === "1") {
          toast.success(data.message || "Mail sent successfully");
        } else if (data.status === "2") {
          toast.warn(data.message || "Warning: Mail already sent");
        } else {
          toast.error(data.message || "Unknown response status");
        }
      } else {
        toast.error("Server error. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoadingState((prevState) => ({ ...prevState, [email]: false }));
    }
  };


  const toggleUserStatus = async (userId) => {
    const userToUpdate = users.find(user => user.id === userId);
    const updatedUser = { ...userToUpdate, isActive: !userToUpdate.isActive };

    try {
      const response = await fetch(`https://college-alumni.callibry.com:8080/update-user/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(updatedUser),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const updatedUsers = users.map(user =>
          user.id === userId ? updatedUser : user
        );
        setUsers(updatedUsers);
      }
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
  };

  const handleSort = (field) => {
    const sortedUsers = [...users].sort((a, b) => {
      if (a[field] < b[field]) return -1;
      if (a[field] > b[field]) return 1;
      return 0;
    });
    setUsers(sortedUsers);
  };

  const filteredUsers = users.filter(user =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const navigate = useNavigate();
  const Handledelete = async (email) => {
    try {
      const url = `https://college-alumni.callibry.com:8080/delete-user/${email}`;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setUsers((prevUsers) => prevUsers.filter((user) => user.email !== email));
        console.log("Deleted data successfully");
      } else {
        console.log("Failed to delete");
      }
    } catch (error) {
      console.log("Error is", error);
    }
  };


  const handleLogout = async () => {
    localStorage.removeItem('isLoggedIn'); // Clear login state
    navigate('/login'); // Redirect to login page
    try {
      const response = await fetch('https://college-alumni.callibry.com:8080/logout/collegealumni@123', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Logout successful');
        localStorage.removeItem('authToken');
        navigate('/login');
      } else {
        console.error('Logout failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // const handleCheckboxChange = (userId) => {
  //   const updatedSelectedUsers = new Set(selectedUsers);
  //   // Toggle selection of individual user
  //   if (updatedSelectedUsers.has(userId)) {
  //     updatedSelectedUsers.delete(userId); // Deselect if already selected
  //   } else {
  //     updatedSelectedUsers.add(userId); // Select if not selected
  //   }

  //   setSelectedUsers(updatedSelectedUsers); // Update state with new selection

  //   setIsAllSelected(updatedSelectedUsers.size === filteredUsers.length); // Check if all are selected
  // };
  const handleCheckboxChange = (userId) => {
     setIsVisible(true)
     

    const updatedSelectedUsers = new Set(selectedUsers);
    // Toggle selection of individual user
    if (updatedSelectedUsers.has(userId)) {
      updatedSelectedUsers.delete(userId); // Deselect if already selected
    } else {
      updatedSelectedUsers.add(userId); // Select if not selected
    }

    setSelectedUsers(updatedSelectedUsers); // Update state with new selection


    // Log the currently selected users as an array
    // console.log('Checked Users:', Array.from(updatedSelectedUsers));
    if(updatedSelectedUsers.size===0){
      setIsVisible(false)
    }

    setIsAllSelected(updatedSelectedUsers.size === filteredUsers.length); // Check if all are selected
  };


  const handleSelectAllChange = () => {
    setIsVisible(true)
    if (isAllSelected) {
      setSelectedUsers(new Set()); // Deselect all
      setIsVisible(false)
    } else {
      const allUserIds = new Set(filteredUsers.map(user => user.email));

      setSelectedUsers(allUserIds); // Select all
    }
    setIsAllSelected(!isAllSelected); // Toggle "Select All" state

  };

  const isUserSelected = (userId) => {

    return selectedUsers.has(userId); // Check if the userId is in the selected set
  };

  const handlerImage = (imagePath) => {
    const url = `https://college-alumni.callibry.com${imagePath}`;
    console.log(url);
    window.open(url, '_blank'); // Open the image URL in a new tab
  };

  const handlerDocs = (documentPath) => {
    const url = `https://college-alumni.callibry.com${documentPath}`;
    console.log(url);
    window.open(url, '_blank'); // Open the image URL in a new tab

  }
  // Function to log selected users
  const handleLogSelectedUsers = () => {
    console.log('Checked Users:', Array.from(selectedUsers));
  };

  return (
    <>
      <ToastContainer />
      <div className="relative overflow-x-auto shadow-lg rounded-lg p-6 bg-white">

        <div className="mb-4 flex gap-5 justify-between">
          <div className='search-select-drop-down flex gap-5'>
            <div className='first'>
              <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-30"
              />
            </div>
            <div className='second flex'>
              <form className="max-w-sm mx-auto mb-4 flex gap-2">
                <div className='flex justify-center items-center'>
                  <label htmlFor="countries" className="block  text-sm font-medium text-gray-900 dark:text-white">
                    SHOW
                  </label>
                </div>

                <select
                  id="countries"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={usersPerPage}
                  onChange={(e) => setUsersPerPage(Number(e.target.value))} // Update users per page
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={users.length}>ALL</option>
                </select>
              </form>

            </div>
            
            <div className="btnn">
              <button
                onClick={handleSendChecked}
                disabled={selectedUsers.size === 0} // Disable if no users are selected
                className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700"
                style={{ display: isVisible ? 'block' : 'none' }}

              >
                SELECTED SEND
              </button>
            </div>

          </div>


          <div className="btnn">
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
            >
              Logout
            </button>
          </div>

        </div>

        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs uppercase bg-gray-100 text-gray-700">
            <tr>
             
               
                <th scope="col" className="px-1 py-3 flex justify-center">
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                </th>
            

              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('first_name')}>Full Name</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('email')}>Email</th>
              <th scope="col" className="px-6 py-3">Contact</th>
              <th scope="col" className="px-6 py-3">Graduation Year</th>
              <th scope="col" className="px-6 py-3">Degree</th>
              <th scope="col" className="px-6 py-3">Branch</th>
              <th scope="col" className="px-6 py-3">Country</th>
              {/* <th scope="col" className="px-6 py-3">Action</th> */}
              <th scope="col" className="px-6 py-3">View Document </th>
              <th scope="col" className="px-6 py-3">View Image </th>
              <th scope="col" className="px-6 py-3">Send Email </th>
              <th scope="col" className="px-6 py-3">Details </th>

            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, index) => (
              <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} border-b`}>
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={isUserSelected(user.email)}
                    onChange={() => handleCheckboxChange(user.email)}
                  />
                </td>
                <td className="px-6 py-4 font-medium">{`${user.first_name} ${user.last_name}`}</td>
                <td className="px-6 py-4">{user.email || "N/A"}</td>
                <td className="px-6 py-4">{user.contact_number || "N/A"}</td>
                <td className="px-6 py-4">{user.graduation_year || "N/A"}</td>
                <td className="px-6 py-4">{user.degree || "N/A"}</td>
                <td className="px-6 py-4">{user.major || "N/A"}</td>
                <td className="px-6 py-4">{user.country || "N/A"}</td>
                <td
                  className="px-6 py-4 font-medium text-blue-600 hover:underline cursor-pointer"
                  onClick={() => { handlerImage(user.document_path) }}
                >
                  Click Here!
                </td>
                <td
                  className="px-6 py-4 font-medium text-blue-600 hover:underline cursor-pointer"
                  onClick={() => {
                    handlerDocs(user.photo_path)
                  }}

                >
                  Click Here!
                </td>
                {/* <td className="px-6 py-4">
                  <button
                    onClick={() => handlecheck(user.email)}
                    className={`bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 ${loadingState[user.email] ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loadingState[user.email]} // Disable only the clicked button while loading
                  >
                    {loadingState[user.email] ? 'Sending...' : 'SEND'}
                  </button>
                </td> */}
                <td className="px-6 py-4">
                  <button
                    onClick={() => handlecheck(user.email)}
                    className={`bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 ${selectedUsers.size > 0 ? 'opacity-50 cursor-not-allowed' : '' // Disable if SendChecked button is active
                      } ${loadingState[user.email] ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loadingState[user.email] || selectedUsers.size > 0} // Disable the Send button if SendChecked is active or while loading
                  >
                    {loadingState[user.email] ? 'Sending...' : 'SEND'}
                  </button>
                </td>



                {<td className="px-6 py-4">
                  <button
                    onClick={() => openEditModal(user)}
                    // className="font-medium text-blue-600 hover:underline"
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                  >
                    ACTION
                  </button>

                  {/* <button  className="bg-red-500 text-white py-2 px-4 rounded hover:text-white-600"  onClick={()=>{Handledelete(user.email)}}  >Delete</button>
           */}
                </td>}
              </tr>
            ))}
          </tbody>
        </table>

        {/* Edit Modal */}
        {editUser && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md">
            <div className="bg-white/80 p-8 rounded-3xl shadow-2xl w-full max-w-3xl mx-4 transform transition-transform duration-300 ease-in-out hover:scale-105">
              <h2 className="text-3xl font-bold text-blue-900 mb-6 text-center tracking-wide">Student Details</h2>

              <form className="space-y-6">
              
                <div className='flex justify-between'>

                  <div className='details1'>
                    <div className='flex gap-5'>
                      <div className='flex flex-col gap-3'>
                        <div className='text-gray-700 font-semibold'>
                          Full Name:
                        </div>
                        <div className='text-gray-700 font-semibold'>
                          Contact:
                        </div>
                        <div className='text-gray-700 font-semibold'>
                          country:
                        </div>
                        <div className='text-gray-700 font-semibold'>Graduation Year: </div>
                      </div>
                      <div className='flex flex-col gap-3'>
                        <div className='text-gray-900'>{`${editUser.first_name} ${editUser.last_name}`}</div>
                        <div className='text-gray-900'>
                          {editUser.contact_number}
                        </div>
                        <div className='text-gray-900'>
                          {editUser.country}
                        </div>
                        <div className='text-gray-900'>
                          {editUser.graduation_year}
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className='details2'>
                    <div className='flex justify-between'>
                      <div className='flex flex-col gap-3'>
                        <div className='text-gray-700 font-semibold'>
                          Email:
                        </div>
                        <div className='text-gray-700 font-semibold'>
                          Degree:
                        </div>
                        <div className='text-gray-700 font-semibold'>
                          Branch:
                        </div>
                        <div className='text-gray-700 font-semibold'>
                          current Organization:
                        </div>
                      </div>
                      <div className='flex flex-col gap-3' >
                        <div className='text-gray-900'>
                          {editUser.email}
                        </div>
                        <div className='text-gray-900'>
                          {editUser.degree}
                        </div>
                        <div className='text-gray-900'>
                          {editUser.major}
                        </div>
                        <div className='text-gray-900'>
                          {editUser.current_organization}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>



                {/* Buttons */}
                <div className="flex justify-end space-x-4 mt-8">
                  {/* <button
                    type="button"
                    onClick={handleSave}
                    className="bg-gradient-to-r from-red-500 to-red-600 text-white px-6 py-2 rounded-full shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
                  >
                    DELETE
                  </button> */}
                  <button
                    type="button"
                    onClick={closeEditModal}
                    className="ml-2 bg-gray-300 text-gray-700 px-6 py-2 rounded-full shadow-lg hover:bg-gray-400 transition-all duration-300 transform hover:scale-105"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="bg-gray-200 px-4 py-2 rounded-md disabled:"
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="bg-gray-200 px-4 py-2 rounded-md disabled"

          >
            Next
          </button>
        </div>
      </div>
      <div>
      </div>
    </>

  );
};

export default AdminUserDetails;


