import React, { useState, useEffect } from 'react';

import Banner from '../../component/banner/Banner';
import ScrollToTop from '../../component/topButtons/ScrollToTop';

import FAQSection from '../../component/FAQSection/FAQSection';
import AchieverSection from '../../component/achieverSection/AchieverSection';
import SelectionNumber from '../../component/selectionNumber/SelectionNumber';
import NewsletterSection from '../../component/newsletterSection/NewsletterSection';
import NavBar from '../../component/navbar/NavBar';
import HeroCarouselItem from '../../component/heroItem/HeroCarouselItem';
import PartnersSection from '../../component/partnersSection/PartnersSection';
import Footer from '../../component/footer/Footer';
import Testonomialst from '../../component/PlacedStudentTestonomial/Testonomialst';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import SimpleModal from '../../component/Modal/SimpleModal';


const Home = () => {

  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal state

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 2000);


    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Banner />
      <NavBar />
      <HeroCarouselItem />
      <SelectionNumber />
      <AchieverSection />
      <Testonomialst />
      <PartnersSection />
      <NewsletterSection />
      <FAQSection />
      <Footer />
      <SimpleModal open={isModalOpen} handleClose={handleCloseModal} />

    </>
  );
};

export default Home;
