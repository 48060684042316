import LoginPage from "../../component/LoginPage/loginSection/LoginPage";

const Login=()=>{
    return (
        <>
        
         <LoginPage/>
        </>
    )
}
export default Login;