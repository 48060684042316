import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonialsData = [
  {
    name: "John Doe",
    course: "Computer Science",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.",
    image: "https://www.mpgi.edu.in/wp-content/uploads/2024/01/24testi_20testi_Shruti.webp"
  },
  {
    name: "Jane Smith",
    course: "Information Technology",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.", 
    image:"https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Nitin_jangir.jpg"
  },
  {
    name: "Sam Wilson",
    course: "Mechanical Engineering",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.",
    image: "https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Akash_Mech.jpg"
  },
  {
    name: "Shruti Singh",
    course: "Civil Engineering",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.",
    image: "https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Md__Suyaib_CSE_IN.jpeg"
  },
  {
    name: "Tanya Verma",
    course: "Electronics Engineering",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.",
    image: "https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Adeeba_CSE_IN.jpeg"
  },
  {
    name: "Ankita Mishra",
    course: "Biotechnology",
    testimonial: "startelelogic is true to what it claims when it comes to a student-centric approach. The placement team helped me a lot during the placement drive. The teachers were helpful and responded to me all my queries. I am grateful for my experience here.",
    image: "https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Abhimanyu_CSE_IN.jpeg"
  }
];

const TestimonialCard = ({ name, course, testimonial, image }) => (
  <div className="testimonial-card flex flex-col items-center justify-center border p-4 rounded shadow-md h-full text-center">
    <img src={image} alt={name} className="testimonial-image w-20 h-20 rounded-full mb-4"/>
    <h3 className="text-xl font-bold mb-2">{name}</h3>
    <p className="text-gray-500 text-sm mb-2">{course}</p>
    <p className="text-gray-700 italic">"{testimonial}"</p>
  </div>
);

const Testonomialst = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Show 3 slides at a time
    slidesToScroll: 3, // Scroll 3 slides at a time
    autoplay: true,    // Auto play carousel
    autoplaySpeed: 3000, // Delay between auto slides (3 seconds)
    arrows: true,       // Show navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="testimonial-container mx-auto max-w-screen-xl">
      <Slider {...settings}>
        {testimonialsData.map((student, index) => (
          <div key={index} className="p-2">
            <TestimonialCard
              name={student.name}
              course={student.course}
              testimonial={student.testimonial}
              image={student.image}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testonomialst;
