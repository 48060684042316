

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress'; // Import Material-UI Circular Progress
import Box from '@mui/material/Box'; // Import Box from Material-UI for layout
const Loader=()=>{
    return(
        <>
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            minHeight="100vh" // Adjust based on your layout
        >
            <CircularProgress /> {/* Circular spinner */}   
        </Box>
        </>
    )
}
export default Loader 
