
import React, { useState } from 'react';
// import { saveAs } from 'file-saver'; // Make sure this package is installed

const DownloadImage = () => {
  const [selectedFormat, setSelectedFormat] = useState('Image'); // Default format is PDF

  const handleDownload = () => {
    const formatMap = {
      'PDF': 'https://example.com/your-pdf-document.pdf', // Replace with your PDF link
      'PNG': 'https://example.com/your-image.png', // Replace with your PNG link
      'JPG': 'https://example.com/your-image.jpg', // Replace with your JPG link
    };

    const fileNameMap = {
      'PDF': 'your-document.pdf',
      'PNG': 'your-image.png',
      'JPG': 'your-image.jpg',
    };

    // saveAs(formatMap[selectedFormat], fileNameMap[selectedFormat]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-teal-500 flex items-center justify-center p-8">
      <div className="max-w-4xl bg-white shadow-lg rounded-lg p-8">
        {/* Header Section */}
       

        {/* Format Selection Section */}
        {/* <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Choose Format</h2>
          <div className="flex justify-center space-x-4">
            <button
              className={`px-4 py-2 font-semibold rounded-lg shadow-md hover:shadow-lg ${selectedFormat === 'PDF' ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-800'}`}
              onClick={() => setSelectedFormat('PDF')}
            >
              PDF
            </button>
            <button
              className={`px-4 py-2 font-semibold rounded-lg shadow-md hover:shadow-lg ${selectedFormat === 'PNG' ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-800'}`}
              onClick={() => setSelectedFormat('PNG')}
            >
              PNG
            </button>
            <button
              className={`px-4 py-2 font-semibold rounded-lg shadow-md hover:shadow-lg ${selectedFormat === 'JPG' ? 'bg-orange-500 text-white' : 'bg-gray-100 text-gray-800'}`}
              onClick={() => setSelectedFormat('JPG')}
            >
              JPG
            </button>
          </div>
          <p className="text-gray-500 text-center mt-4">Currently selected format: {selectedFormat}</p>
        </div> */}

        {/* Document Preview Section */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4 text-center">Image Preview</h2>
          <div className="flex justify-center">
            <img 
              src="https://via.placeholder.com/300x400" // Replace with your thumbnail
              alt="Document Preview" 
              className="border border-gray-300 rounded-md shadow-md"
            />
          </div>
          <p className="text-gray-500 text-center mt-4">Preview before downloading in your chosen format.</p>
        </div>

        {/* PDF Download Button */}
        <div className="text-center mb-8">
          <button
            onClick={handleDownload}
            className="px-8 py-4 bg-gradient-to-r from-green-400 to-blue-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-200 ease-in-out"
          >
            Download {selectedFormat}
          </button>
        </div>

        {/* Additional Information */}
        {/* <div className="text-center">
          <p className="text-gray-500 text-sm">File Size: 2 MB | Format: {selectedFormat}</p>
        </div> */}
      </div>
    </div>
  );
};

export default DownloadImage;






