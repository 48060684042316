


import React from "react";
import CountUp from "react-countup";
import selectionLogo from '../../assets/image/heroImage1.jpg';
import client from '../../assets/client.png'
import project from '../../assets/project.png'
import partnership from '../../assets/partnership.png'
import employee from '../../assets/employee.png'

const Card = ({ logo, title, number }) => {
  return (
    <div className="bg-white bg-opacity-75 p-4 sm:p-6 flex flex-col items-center sm:items-start justify-center rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 p-6 space-y-4 hover:scale-105">
      <div className="flex flex-col sm:flex-row sm:items-center ">
        <img src={logo} alt={`${title} logo`} className="w-12 h-12 sm:w-16 sm:h-16" />
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mt-2 sm:ml-4">{title}</h2>
      </div>
      <p className="text-3xl sm:text-4xl font-bold text-yellow-700 mt-2">
        <CountUp start={0} end={number} duration={2.5} />+
      </p>
    </div>
  );
};

const SelectionNumber = () => {
  const cards = [
    {
      logo: partnership, // Replace with your logo URLs
      title: "Partners",
      number: 1000,
    },
    {
      logo: project,
      title: "Projects",
      number: 750,
    },
    {
      logo: employee,
      title: "Employees",
      number: 500,
    },
    {
      logo: client,
      title: "Clients",
      number: 1200,
    },
  ];

  return (
    <div className="relative mb-12">
      <div className="bg-slate-400 py-6 px-4 sm:py-8 sm:px-10 relative">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
          {cards.map((card, index) => (

            <Card key={index} logo={card.logo} title={card.title} number={card.number} />
          ))}  
        </div>
      </div>
    </div>
  );
};
export default SelectionNumber;

