import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa'; // Import plus and minus icons

// Single FAQ Item Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-300 mb-4">
      {/* Question - Clickable */}
      <div
        className="flex justify-between items-center cursor-pointer p-4 bg-gradient-to-r from-purple-500 to-blue-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold text-white">{question}</h3>
        <div className="text-white">
          {/* Toggle Plus/Minus icon based on isOpen state */}
          {isOpen ? <FaMinus /> : <FaPlus />}
        </div>
      </div>

      {/* Answer Section with Animation */}
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
          isOpen ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <p className="p-4 text-gray-700 bg-white rounded-lg shadow-inner">
          {answer}
        </p>
      </div>
    </div>
  );
};

// Main FAQ Section Component
const FAQSection = () => {
  // FAQ Data - list of questions and answers
  const faqData = [
    {
      question: 'What services do you offer?',
      answer:
        'We offer a wide range of services including web development, app development, and digital marketing.',
    },
    {
      question: 'How long does it take to complete a project?',
      answer:
        'Project timelines vary depending on the complexity, but generally, we aim to complete most projects within 4-6 weeks.',
    },
    {
      question: 'What are your pricing models?',
      answer:
        'We offer both hourly and project-based pricing depending on the scope of the work.',
    },
    {
      question: 'Do you provide post-launch support?',
      answer:
        'Yes, we provide continuous support and maintenance for all projects after launch.',
    },
  ];

  return (
    <div className="max-w-4xl mx-auto py-12 " >
      <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">
        Frequently Asked Questions
      </h2>
      {/* Loop through FAQ data and render FAQItem components */}
      <div className="space-y-4 ">
        {faqData.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
