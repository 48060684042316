
import React from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { achievers } from './achieverData'; // Import achiever data
import NavBar from '../navbar/NavBar';

const AchieverSection = () => {
  const navigate = useNavigate();

  // Function to navigate to achiever detail page based on ID
  const handleClick = (id) => {
    navigate(`/achiever/${id}`); // Redirect to a dynamic achiever page with ID
  };

  // Navigate to the full achievers list
  const handleViewAll = () => {
    navigate('/achiever/1'); // Redirect to the main achievers page
  };

  return (
    <>
    
    
    <div className="bg-gradient-to-b from-gray-100 to-gray-200 py-12">
      <div className="max-w-7xl mx-auto relative">
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-900">
          Our Achievers
        </h2>

        {/* Achiever Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {achievers.map((achiever) => (
            <div
              key={achiever.id}
              className="relative group bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 cursor-pointer transform hover:-translate-y-2 hover:scale-105"
              // onClick={() => handleClick(achiever.id)}
            >
              {/* Image and Info Section */}
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <img
                    src={achiever.imageUrl}
                    alt={achiever.name}
                    className="w-32 h-32 rounded-full object-cover border-4 border-blue-500 group-hover:border-pink-500 transition-all duration-300"
                  />
                  <span className="absolute top-0 right-0 w-4 h-4 bg-green-400 border-2 border-white rounded-full group-hover:animate-pulse"></span>
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-gray-800 group-hover:text-blue-500 transition-colors duration-300">
                    {achiever.name}
                  </h3>
                  <p className="text-gray-600">{achiever.achievement}</p>
                  {/* Achievement Badge */}
                  <div className="mt-2">
                    <span className="text-sm inline-block bg-blue-100 text-blue-600 py-1 px-3 rounded-full">
                      {achiever.category || 'Top Achiever'}
                    </span>
                  </div>
                </div>
              </div>

              {/* Hover Overlay with Tooltip */}
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 flex items-center justify-center opacity-0 group-hover:opacity-100">
                <p className="text-white text-sm font-semibold px-4 py-2 bg-blue-600 rounded-lg">
                  Click to see {achiever.name}'s achievements
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* View All Button */}
        <div className="flex justify-end mt-8">
          <button
            // onClick={handleViewAll}
            className="bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
          >
            View All Achievers
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default AchieverSection;
