import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Home from '../page/home/Home'

import About from '../page/about/About'

import Testimonal from '../page/testimonal/Testimonal'
import Contact from '../page/contact/Contact'
import Register from '../page/register/Register'
import Placement from '../page/placement/Placement'
import Login from '../page/Login/Login'
import AchieverDetail from '../page/achieverDetail/AchieverDetail.jsx/AchieverDetail'
import ForgotPassword from '../page/forgotPassword/ForgotPassword'
import Academics from '../page/Academics/Academics'
import AdminUser from '../page/adminUser/AdminUser'
import SideBar from '../component/sideBar/SideBar'
import DownloadImage from '../page/downloadDocument/downloadImage/DownloadImage'
import DownloadMarksheet from '../page/downloadDocument/downloadMarksheet/DownloadMarksheet'
import ProtectedRoutes from './protectedRoutes/ProtectedRoutes'

export const AllRoutes = createBrowserRouter([
    {path:'/',element:<Home/>},
        
        {path:'about' ,element:<About/>},
        {path:'testimonal',element:<Testimonal/>},
        {path:'contact' , element:<Contact/>},
        {path:'register' , element:<Register/>},
        {path:'placement' , element:<Placement/>},
        { path:'login' ,element :<Login/>},
        { path:"/achiever/:id", element:<AchieverDetail />},
        {path: "/forgotpassword", element:<ForgotPassword/>},
        {path: "Academics", element:<Academics/>},
        {path: "/Adminuser",element:<AdminUser/>},
        {path:"/sideBar",element: <SideBar/>},
        {path: "/img" , element: <DownloadImage/>},
        {path: "/imgm" , element: <DownloadMarksheet/>},
      
])

