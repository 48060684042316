import React from 'react';

const alumniData = [
  {
    name: 'John Doe',
    batch: '2010',
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: 'https://example.com/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  {
    name: 'John Doe',
    batch: '2010',
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: 'https://example.com/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  {
    name: 'John Doe',
    batch: '2010',
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: 'https://example.com/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  {
    name: 'John Doe',
    batch: '2010',
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: 'https://example.com/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  {
    name: 'John Doe',
    batch: '2010',
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: '://example.cohttpsm/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  {
    name: 'John Doe',
    batch: '2010' ,  
    department: 'Computer Science',
    currentRole: 'Software Engineer at Google',
    image: 'https://example.com/john.jpg',
    courses: ['Data Structures', 'Algorithms', 'Operating Systems', 'Web Development'], // Add courses here
  },
  // Add more alumni data here
];

const AlumniCard = ({ alumni }) => {
  return (
    <div className="max-w-sm bg-white border rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-105 hover:shadow-2xl">
      <img className="w-full h-48 object-cover" src={alumni.image} alt={`${alumni.name}`} />
      <div className="p-6">
        <h2 className="text-2xl font-semibold">{alumni.name}</h2>
        <p className="text-gray-600">{alumni.department}, Batch of {alumni.batch}</p>
        <p className="mt-3 text-gray-800">{alumni.currentRole}</p>
        {/* Courses Enrolled Section */}
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Courses Enrolled:</h3>
          <ul className="list-disc list-inside text-gray-700 mt-2">
            {alumni.courses.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Academics = () => {
  return (
    <div className="px-8 py-12 bg-gray-100">
      <h1 className="text-4xl font-bold mb-6">Our Distinguished Alumni</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {alumniData.map((alumni, index) => (
          <AlumniCard key={index} alumni={alumni} />
        ))}
      </div>
    </div>
  );
};

export default Academics;
