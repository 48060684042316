import React from 'react'
import Placementcontent from '../../component/PlacedStudentTestonomial/Placementcontent'
import Testonomialst from '../../component/PlacedStudentTestonomial/Testonomialst'
import Layout from '../../layout/Layout'
import CompnentHero from '../../component/compnentHero/CompnentHero'
import BreadCrumb from '../../component/breadCrumb/BreadCrumb'
import missionImg from "../../assets/image/heroImage1.jpg";
import registerlogo from '../../assets/Stl4.png'

const Placement = () => {
    const register={
        title:"PLACEMENT",
        content: "Some glory and victory to more Updates",
        logo : registerlogo
    }

    const breadcrumbItems = [
        { label: 'Home', path: '/' },
        { label: 'Placement', path: '/placement' }
      ];

  return (
     
    <div>
        <Layout>
        <CompnentHero title={register.title} content={register.content} heroImg={register.logo}/>
        <BreadCrumb breadcrumbItems={breadcrumbItems} />
            <Placementcontent/>
            <Testonomialst/> 
            <br> 
            </br>
            <Testonomialst/> 
            <br> 
            </br>
            
        </Layout>
        
      
    </div>
  )
}

export default Placement
