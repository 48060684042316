import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Loader from '../Loader/Loader';
import { useEffect } from 'react';
import bg1 from '../../assets/stl.png'
import bg2 from '../../assets/Stl3.png'
import bg3 from '../../assets/Stl4.png'
import bg4 from '../../assets/Stl2.png'
import {
    Box,
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const MIN_FILE_SIZE_IMAGE = 10 * 1024; // 10 Kb
const MIN_FILE_SIZE_DOCS = 100 * 1024;  //100kb
const MAX_FILE_SIZE_IMAGE = 50 * 1024; // 50kb
const MAX_FILE_SIZE_DOCS = 500 * 1024  // 500kb



const countries = [
    { code: 'IN', name: 'India' },
    { code: 'US', name: 'United States' },
    { code: 'CA', name: 'Canada' },
    { code: 'GB', name: 'United Kingdom' },
    { code: 'AU', name: 'Australia' },
    { code: 'AF', name: 'Afghanistan' },
    { code: 'AL', name: 'Albania' },
    { code: 'DZ', name: 'Algeria' },
    { code: 'AS', name: 'American Samoa' },
    { code: 'AD', name: 'Andorra' },
    { code: 'AO', name: 'Angola' },
    { code: 'AI', name: 'Anguilla' },
    { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua and Barbuda' },
    { code: 'AR', name: 'Argentina' },
    { code: 'AM', name: 'Armenia' },
    { code: 'AW', name: 'Aruba' },
    { code: 'AU', name: 'Australia' },
    { code: 'AT', name: 'Austria' },
    { code: 'AZ', name: 'Azerbaijan' },
    { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' },
    { code: 'BD', name: 'Bangladesh' },
    { code: 'BB', name: 'Barbados' },
    { code: 'BY', name: 'Belarus' },
    { code: 'BE', name: 'Belgium' },
    { code: 'BZ', name: 'Belize' },
    { code: 'BJ', name: 'Benin' },
    { code: 'BM', name: 'Bermuda' },
    { code: 'BT', name: 'Bhutan' },
    { code: 'BO', name: 'Bolivia' },
    { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { code: 'BA', name: 'Bosnia and Herzegovina' },
    { code: 'BW', name: 'Botswana' },
    { code: 'BV', name: 'Bouvet Island' },
    { code: 'BR', name: 'Brazil' },
    { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'BN', name: 'Brunei Darussalam' },
    { code: 'BG', name: 'Bulgaria' },
    { code: 'BF', name: 'Burkina Faso' },
    { code: 'BI', name: 'Burundi' },
    { code: 'CV', name: 'Cabo Verde' },
    { code: 'KH', name: 'Cambodia' },
    { code: 'CM', name: 'Cameroon' },
    { code: 'CA', name: 'Canada' },
    { code: 'KY', name: 'Cayman Islands' },
    { code: 'CF', name: 'Central African Republic' },
    { code: 'TD', name: 'Chad' },
    { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' },
    { code: 'CX', name: 'Christmas Island' },
    { code: 'CC', name: 'Cocos (Keeling) Islands' },
    { code: 'CO', name: 'Colombia' },
    { code: 'KM', name: 'Comoros' },
    { code: 'CD', name: 'Congo (the Democratic Republic of the)' },
    { code: 'CG', name: 'Congo' },
    { code: 'CK', name: 'Cook Islands' },
    { code: 'CR', name: 'Costa Rica' },
    { code: 'HR', name: 'Croatia' },
    { code: 'CU', name: 'Cuba' },
    { code: 'CW', name: 'Curaçao' },
    { code: 'CY', name: 'Cyprus' },
    { code: 'CZ', name: 'Czechia' },
    { code: 'DK', name: 'Denmark' },
    { code: 'DJ', name: 'Djibouti' },
    { code: 'DM', name: 'Dominica' },
    { code: 'DO', name: 'Dominican Republic' },
    { code: 'EC', name: 'Ecuador' },
    { code: 'EG', name: 'Egypt' },
    { code: 'SV', name: 'El Salvador' },
    { code: 'GQ', name: 'Equatorial Guinea' },
    { code: 'ER', name: 'Eritrea' },
    { code: 'EE', name: 'Estonia' },
    { code: 'SZ', name: 'Eswatini' },
    { code: 'ET', name: 'Ethiopia' },
    { code: 'FK', name: 'Falkland Islands (the)' },
    { code: 'FO', name: 'Faroe Islands' },
    { code: 'FJ', name: 'Fiji' },
    { code: 'FI', name: 'Finland' },
    { code: 'FR', name: 'France' },
    { code: 'GF', name: 'French Guiana' },
    { code: 'PF', name: 'French Polynesia' },
    { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' },
    { code: 'GM', name: 'Gambia' },
    { code: 'GE', name: 'Georgia' },
    { code: 'DE', name: 'Germany' },
    { code: 'GH', name: 'Ghana' },
    { code: 'GI', name: 'Gibraltar' },
    { code: 'GR', name: 'Greece' },
    { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' },
    { code: 'GP', name: 'Guadeloupe' },
    { code: 'GU', name: 'Guam' },
    { code: 'GT', name: 'Guatemala' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'GN', name: 'Guinea' },
    { code: 'GW', name: 'Guinea-Bissau' },
    { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' },
    { code: 'HM', name: 'Heard Island and McDonald Islands' },
    { code: 'VA', name: 'Holy See' },
    { code: 'HN', name: 'Honduras' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'HU', name: 'Hungary' },
    { code: 'IS', name: 'Iceland' },
    { code: 'IN', name: 'India' },
    { code: 'ID', name: 'Indonesia' },
    { code: 'IR', name: 'Iran' },
    { code: 'IQ', name: 'Iraq' },
    { code: 'IE', name: 'Ireland' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'IL', name: 'Israel' },
    { code: 'IT', name: 'Italy' },
    { code: 'JM', name: 'Jamaica' },
    { code: 'JP', name: 'Japan' },
    { code: 'JE', name: 'Jersey' },
    { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakhstan' },
    { code: 'KE', name: 'Kenya' },
    { code: 'KI', name: 'Kiribati' },
    { code: 'KP', name: 'Korea (the Democratic People\'s Republic of)' },
    { code: 'KR', name: 'Korea (the Republic of)' },
    { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kyrgyzstan' },
    { code: 'LA', name: 'Lao People\'s Democratic Republic' },
    { code: 'LV', name: 'Latvia' },
    { code: 'LB', name: 'Lebanon' },
    { code: 'LS', name: 'Lesotho' },
    { code: 'LR', name: 'Liberia' },
    { code: 'LY', name: 'Libya' },
    { code: 'LI', name: 'Liechtenstein' },
    { code: 'LT', name: 'Lithuania' },
    { code: 'LU', name: 'Luxembourg' },
    { code: 'MO', name: 'Macao' },
    { code: 'MG', name: 'Madagascar' },


];
const branches = [
    { code: 'CS', name: 'Computer Science' },
    { code: 'IT', name: 'Information Technology' },
    { code: 'SE', name: 'Software Engineering' },
    { code: 'DS', name: 'Data Science' },
    { code: 'AI', name: 'Artificial Intelligence' },
    { code: 'ML', name: 'Machine Learning' },
    { code: 'NW', name: 'Networking' },
    { code: 'DB', name: 'Database Management' },
    { code: 'WD', name: 'Web Development' },
    { code: 'UI', name: 'User Interface Design' },
    { code: 'UX', name: 'User Experience Design' },
    { code: 'CY', name: 'Cybersecurity' },
    { code: 'OB', name: 'OTHER' }
];
const degree = [
    { name: 'B-TECH' },
    { name: 'BSC' },
    { name: 'BCA' },
    { name: 'MCA' },
    { name: 'M-TECH' },
    { name: 'MS' },
    { name: 'MA' },
    { name: 'BA' },
    { name: 'BBA' },
    { name: 'MBA' },
    { name: 'OTHER' }
]
const FILE_SUPPORTED_FORMATS = ['application/pdf', 'application/msword', 'image/png'];
const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    graduationYear: Yup.number()
        .required('Graduation Year is required')
        .min(1900, 'Year must be after 1900')
        .max(new Date().getFullYear(), `Year cannot be after ${new Date().getFullYear()}`),
    degree: Yup.string().required('Degree is required'),
    major: Yup.string().required('Major is required'),
    contactNumber: Yup.string()
        .required('Contact Number is required')
        .matches(/^[0-9]+$/, 'Contact Number must be numeric')
        .min(10, 'Contact Number must be at least 10 digits')
        .max(10, 'Contact Number at max 10 digit'),
    // .max(15, 'Contact Number must be at most 15 digits')
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    document: Yup.mixed()
        .required('Document is required')
        .test('fileFormat', 'Unsupported file format. Only PDF, DOC, and PNG allowed.', (value) => {
            return value && value.type && FILE_SUPPORTED_FORMATS.includes(value.type);
        })
        .test('fileSize', 'File too large. Maximum size is 500kb.', (value) => {
            return value && value.size <= MAX_FILE_SIZE_DOCS;
        })
        .test('fileSize', 'File too small. Minimum size is 100kb.', (value) => {
            return value && value.size >= MIN_FILE_SIZE_DOCS;
        }),

    photo: Yup.mixed()
        .required('Photo is required')
        .test('fileFormat', 'Unsupported file format. Only PNG, JPG, JPEG allowed.', (value) => {
            return value && value.type && ['image/png', 'image/jpg', 'image/jpeg'].includes(value.type);
        })
        .test('fileSize', 'File too large. Maximum size is 50kb.', (value) => {
            return value && value.size <= MAX_FILE_SIZE_IMAGE;
        })
        .test('fileSize', 'File too small. Minimum size is 10KB.', (value) => {
            return value && value.size >= MIN_FILE_SIZE_IMAGE;
        }),

});
const RegistrationForm = () => {
    const [loading, setLoading] = useState(false); // State for loading
    const [selectedDegree, setSelectedDegree] = useState('');
    const [selectedMajor, setSelectedMajor] = useState('');

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        graduationYear: '',
        degree: '',
        major: '',
        contactNumber: '',
        country: '',
        address: '',
        document: null,
        photo: null,
        designation: '',
        currentorg: '',

    };

    const onSubmit = async (values, { resetForm }) => {
        setLoading(true); // Start loading
        const formData = new FormData();
        formData.append('first_name', values.firstName);
        formData.append('last_name', values.lastName);
        formData.append('email', values.email);
        formData.append('graduation_year', values.graduationYear);
        formData.append('degree', values.degree);
        formData.append('major', values.major);
        formData.append('contact_number', values.contactNumber);
        formData.append('country', values.country);
        formData.append('document', values.document);
        formData.append('photo', values.photo);
        formData.append('designation', values.designation)
        formData.append('current_organization', values.currentorg)

        try {
            const response = await fetch('https://college-alumni.callibry.com:8080/upload', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            });
            const data = await response.json();
            if (response.ok) {
               
                if (data.status === "0") {
                    console.log("Error");
                    toast.error(data.message || "Error sending mail");
                  } else if (data.status === "1") {
                    console.log("Mail sent successfully");
                    toast.success(data.message || "Mail sent successfully");
                  }
                   else if (data.status === "2") {
                    console.log("Warning: Mail already sent");
                    toast.warn(data.message || "Warning: Mail already sent");
                  } 
                  else {
                    console.log("Unknown status");
                    toast.error(data.message || "Unknown response status");

                  }
                resetForm();
            } else {
                const errorDetails = await response.text();
                console.error('Form submission failed:', errorDetails);
                toast.warn(data.message);
            }
        } catch (error) {
            console.error('Error occurred while submitting the form', error);
            toast.error('Error occurred while submitting the form. Please try again later.');
        }
        finally {
            setLoading(false);
        }
    };


    const handleDegreeChange = (e, setFieldValue) => {
        const value = e.target.value;
        setSelectedDegree(value);
        setFieldValue('degree', value);
    };

    const handleMajorChange = (e, setFieldValue) => {
        const value = e.target.value;
        setSelectedMajor(value);
        setFieldValue('major', value);
    };
    const images = [bg1, bg4, bg2, bg3]; // Array of images
    const [currentImage, setCurrentImage] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 2000); // Change image every 2 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);

    return (
        <>
            <ToastContainer />
            <div className="flex flex-row p-8 gap-10" >
                {/* <div className='first-left-data flex justify-center '>
                  
                </div> */}
                <div className='right-side data w-full h-full'>
                    <div className="form-heading flex justify-center items-center bg-gray-100 p-8 font-bold text-xl text-black underline">
                        ALUMNI REGISTRATION FORM
                    </div>
                    <div className="form-item bg-green-300">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '100vh',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '75%',
                                    // maxWidth: '1000px',
                                    backgroundColor: 'white',
                                    padding: '2rem',
                                    borderRadius: '18px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}
                            >  {loading ? (
                                <Loader />
                            ) : (
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ handleSubmit, setFieldTouched, errors, touched, setFieldValue }) => (
                                        <Form onSubmit={handleSubmit} >
                                            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Field
                                                        name="firstName"
                                                        as={TextField}
                                                        label="First Name"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="firstName" />}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                        onBlur={() => setFieldTouched('firstName')}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Field
                                                        name="lastName"
                                                        as={TextField}
                                                        label="Last Name"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="lastName" />}
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                        onBlur={() => setFieldTouched('lastName')}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field
                                                        name="email"
                                                        as={TextField}
                                                        label="Email"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="email" />}
                                                        error={touched.email && Boolean(errors.email)}
                                                        onBlur={() => setFieldTouched('email')}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Field
                                                        name="graduationYear"
                                                        as={TextField}
                                                        label="Graduation Year"
                                                        type="number"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="graduationYear" />}
                                                        error={touched.graduationYear && Boolean(errors.graduationYear)}
                                                        onBlur={() => setFieldTouched('graduationYear')}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth variant="outlined" error={touched.degree && Boolean(errors.degree)}>
                                                        <InputLabel>Degree</InputLabel>
                                                        <Field
                                                            name="degree"
                                                            as={Select}
                                                            label="Degree"
                                                            onBlur={() => setFieldTouched('degree')}
                                                            onChange={(e) => handleDegreeChange(e, setFieldValue)}
                                                        >
                                                            {degree.map((deg) => (
                                                                <MenuItem key={deg.name} value={deg.name}>
                                                                    {deg.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="degree" component="p" className="text-red-500" />
                                                    </FormControl>

                                                    {selectedDegree === 'OTHER' && (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Enter your degree"
                                                            onBlur={() => setFieldTouched('degree')}
                                                            onChange={(e) => setFieldValue('degree', e.target.value)}
                                                            margin="normal"
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth variant="outlined" error={touched.major && Boolean(errors.major)}>
                                                        <InputLabel>Major</InputLabel>
                                                        <Field
                                                            name="major"
                                                            as={Select}
                                                            label="Major"
                                                            onBlur={() => setFieldTouched('major')}
                                                            onChange={(e) => handleMajorChange(e, setFieldValue)}
                                                        >
                                                            {branches.map((br) => (
                                                                <MenuItem key={br.name} value={br.name}>
                                                                    {br.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="major" component="p" className="text-red-500" />
                                                    </FormControl>

                                                    {selectedMajor === 'OTHER' && (
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Enter the Major"
                                                            onBlur={() => setFieldTouched('major')}
                                                            onChange={(e) => setFieldValue('major', e.target.value)}
                                                            margin="normal"
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Field
                                                                name="contactNumber"
                                                                as={TextField}
                                                                label="Contact Number"
                                                                fullWidth
                                                                variant="outlined"
                                                                helperText={<ErrorMessage name="contactNumber" />}
                                                                error={touched.contactNumber && Boolean(errors.contactNumber)}
                                                                onBlur={() => setFieldTouched('contactNumber')}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl fullWidth variant="outlined" error={touched.country && Boolean(errors.country)}>
                                                                <InputLabel>Country</InputLabel>
                                                                <Field
                                                                    name="country"
                                                                    as={Select}
                                                                    label="Country"
                                                                    onBlur={() => setFieldTouched('country')}
                                                                >
                                                                    {countries.map((country) => (
                                                                        <MenuItem key={country.code} value={country.name}>
                                                                            {country.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage name="country" component="p" className="text-red-500" />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field
                                                        name="address"
                                                        as={TextField}
                                                        label="Address"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="address" />}
                                                        error={touched.address && Boolean(errors.address)}
                                                        onBlur={() => setFieldTouched('address')}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="designation"
                                                        as={TextField}
                                                        label="Designation"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="designation" />}
                                                        error={touched.address && Boolean(errors.designation)}
                                                        onBlur={() => setFieldTouched('designation')}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="currentorg"
                                                        as={TextField}
                                                        label="Current Oragnization"
                                                        fullWidth
                                                        variant="outlined"
                                                        helperText={<ErrorMessage name="currentorg" />}
                                                        error={touched.address && Boolean(errors.org)}
                                                        onBlur={() => setFieldTouched('currentorg')}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <div className='flex flex-col gap-4'>
                                                        <div className='p'> Upload Document <span className='text-red-500'>(pdf, doc, docs)</span></div>
                                                        <input
                                                            type="file"
                                                            name="document"
                                                            onChange={(event) => {
                                                                setFieldValue('document', event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <input
                                                        type="file"
                                                        name="document"
                                                        onChange={(event) => {
                                                            setFieldValue('document', event.currentTarget.files[0]);
                                                        }}
                                                    /> */}
                                                    <ErrorMessage name="document" component="div" className="text-red-500" />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <div className='flex flex-col gap-4'>
                                                        <div className='p'>Upload Image <span className='text-red-500'>(png, jpeg, jpg)</span></div>
                                                        <input
                                                            type="file"
                                                            name="photo"
                                                            onChange={(event) => {
                                                                setFieldValue('photo', event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <ErrorMessage name="photo" component="div" className="text-red-500" />
                                                    </div>

                                                </Grid>

                                                <Grid item xs={6} >
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth


                                                    >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                            </Box>
                        </Box>
                    </div>

                </div>

            </div>
        </>
    );
};

export default RegistrationForm;



