// import React, { useState } from 'react';
// import AdminUserDetails from './adminUserDetails/AdminUserDetails';
// import SideBar from '../../component/sideBar/SideBar';
// import AdminData from './adminData/AdminData';
import NavBar from "../../component/navbar/NavBar";

import AdminUserDetails from "./adminUserDetails/AdminUserDetails";
import Footer from "../../component/footer/Footer";
const AdminUser = () => {

  return (

    <>
      {/* <NavBar/> */}
      <div>
        <AdminUserDetails />
      </div>
      <Footer />

    </>

  );
};

export default AdminUser;


