import React, { useState } from 'react'

import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import collegeLogo from '../../assets/images/logo.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import newLogo from '../../assets/newLogo.png'

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Testimonal', href: '/testimonal' },
    { name: 'Contact', href: '/contact' },
    {name :'Register' ,href:'/register'},
    {name :'Placement' ,href:'/placement'}
  ]
const NavBar = () => {
    const navigate=useNavigate()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const handleLogIn=()=>{
      navigate('/login')
    }

  return (
    <>
    <div >
    <header className=" shadow-lg shadow-black ">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <Link to={'/'} className="-m-1.5 p-1.5">
              <span className="sr-only">Your CPrASNSN</span>
              <img
                alt="logoCollege"
                // src={collegeLogo}
                src={newLogo}
                className="h-12 w-auto"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
             
              <NavLink key={item.name} to={item.href}  
              className={({ isActive }) => 
                isActive 
                  ? 'text-red-500 text-lg font-medium hover:text-red-500' 
                  : 'text-gray-700 text-lg font-medium hover:text-red-500'
              }
            >
              
                {item.name}
              </NavLink>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button onClick={handleLogIn} className="px-6 py-3 text-lg font-semibold text-white bg-sky-500 hover:bg-sky-700 rounded-full shadow-lg transition duration-300 ease-in-out">
                     Admin Log In
                    </button>
          {/* <button class=" p-2 pr-4 pl-4  text-white bg-sky-500 hover:bg-sky-700 rounded-lg">Log In</button> */}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to={'/'} className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt="collegeLogo"
                  src={collegeLogo}
                  className="h-10 w-auto"
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700 hover:bg-red-400 hover:text-white"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6  " />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-400/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="py-5">
                <button onClick={handleLogIn}  className="px-6 py-3 text-lg font-semibold text-white bg-sky-500 hover:bg-sky-700 rounded-full shadow-lg transition duration-300 ease-in-out">
                     Admin Login
                    </button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      </div>  
    
    </>
  )
}

export default NavBar
