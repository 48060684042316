import React from 'react';
import { useParams } from 'react-router-dom';
import { achievers } from '../achieverSection/achieverData'; // Import achiever data

const AchieverDetailSection = () => {
  const { id } = useParams(); // Get the achiever ID from the URL params
  const achiever = achievers.find((a) => a.id === parseInt(id)); // Find achiever by ID

  if (!achiever) {
    return <div>Achiever not found</div>;
  }

  return (
    <div className="max-w-4xl mx-auto py-12">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          {achiever.name}
        </h2>
        <img
          src={achiever.imageUrl}  
          alt={achiever.name}
          className="w-64 h-64 object-cover rounded-lg mb-4"
        />
        <p className="text-gray-600">{achiever.achievement}</p>
      </div>
    </div>
  );
};

export default AchieverDetailSection;
