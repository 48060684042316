

import Testonomialst from './Testonomialst';

const Placementcontent = () => {
  return (
    <>
      <div className="flex flex-col gap-16 p-8 bg-gradient-to-b from-white to-gray-100 mt-8 rounded-lg shadow-md animate-fadeIn">
        <div className="placement-details-para space-y-6">
          <div className="placement-heading text-4xl font-extrabold text-center text-black-300 animate-bounceIn">
            Placement 
          </div>
          <div className="placement-para text-lg text-gray-700 leading-relaxed tracking-wide">
            UD’s students are aided in making their career choices meaningful by connecting to external organizations, bringing a broad spectrum of opportunities to meet the diverse requirements and aspirations of the students.
            <br /><br />
            Career planning is an integral part of the design curriculum. Throughout the course, students are trained in best practices in the industry and groomed for professional life. We aim to ensure that the transition from student to employee is as smooth as possible. 
            <br /><br />
            Graduation Projects & Placements opportunities are offered to all eligible WUD students on a choice-based model. Since 2021, more than 200 reputed corporate houses have engaged with the World University of Design, with more than 90% of eligible students securing exciting career prospects.
            <br /><br />
            Graduation projects undertaken during the last semester of the academic tenure provide students with a hands-on experience guided by both faculty and industry mentors. Students often receive Pre-Placement Offers through this engagement.
          </div>
        </div>
        
        <div className="placement-image flex justify-center">    
          <img
            className="h-[250px] w-[250px] rounded-full shadow-xl transition-transform duration-500 hover:scale-105"
            src="https://worlduniversityofdesign.ac.in/img/CTC.png"
            alt="Description"
          />
        </div>

        <div className="recruiters-section space-y-6">
          <div className="placement-recruiters text-3xl font-semibold text-center text-black">
            Our Recruiters
          </div>
          <div className="flex justify-center gap-4 flex-wrap flex-col ">
            <img
              className="w-30 h:30 object-fit rounded-lg shadow-md hover:scale-90 transition-transform duration-500"
              src="https://worlduniversityofdesign.ac.in/img/Architecture.png"
              alt="Architecture Recruiter"
            />
            <img
              className="w-30 h-30 object-fit rounded-lg shadow-md hover:scale-90 transition-transform duration-500"
              src="https://worlduniversityofdesign.ac.in/img/Business.png"
              alt="Business Recruiter"
            />
          </div>
        </div>

        <div className="testimonials-section space-y-6">
          <div className="heading-test text-3xl font-semibold text-center text-black">
            Student Testimonials
          </div>
        </div>
      </div>
    </>
  );
};

export default Placementcontent;
