// import { XMarkIcon } from '@heroicons/react/20/solid'


// export default function Banner() {
//     return (
//       <>
  

       


//     <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-red-200 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
//       <div
//         aria-hidden="true"
//         className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
//       >
//         <div
//           style={{
//             clipPath:
//               'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
//           }}
//           className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
//         />
//       </div>
//       <div
//         aria-hidden="true"
//         className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
//       >
//         <div
//           style={{
//             clipPath:
//               'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
//           }}
//           className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
//         />
//       </div>
//       <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
//         <p className="text-sm leading-6 text-gray-900">
//           <strong className="font-semibold">GeneriCon 2023</strong>
//           <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
//             <circle r={1} cx={1} cy={1} />
//           </svg>
//           Join us in Denver from June 7 – 9 to see what’s coming next.
//         </p>
//         <a
//           href="#d"
//           className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
//         >
//           Register now <span aria-hidden="true">&rarr;</span>
//         </a>
//       </div>
//       <div className="flex flex-1 justify-end">
//         <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
//           <span className="sr-only">Dismiss</span>
//           <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
//         </button>
//       </div>
//     </div>
  
//       </>
//     )
//   }
  


import React, { useState } from "react";
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function Banner() {
  const [visible, setVisible]=useState('true')
  const Handler=()=>{
    setVisible(false);
  };
  if(!visible){
    return null
  }
  return (
    <div className="relative isolate flex flex-col justify-center sm:flex-row items-center gap-x-4 gap-y-2 overflow-hidden bg-red-200 px-4 py-2 sm:px-6 sm:py-3 md:px-8 md:py-4 lg:px-10 lg:py-5">
      {/* Background Decorative Elements */}
      <div
        aria-hidden="true"
        className="absolute left-0 top-1/2 -z-10 transform -translate-y-1/2 blur-xl opacity-30 w-64 sm:w-72 md:w-96 lg:w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc]"
      ></div>

      {/* Content */}
      <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-4">
        <p className="text-xs sm:text-sm md:text-base leading-5 text-gray-900">
          <strong className="font-semibold">GeneriCon 2024</strong>
          <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-1 sm:mx-2 inline h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          {/* Join us in Denver from June 7 – 9 to see what’s coming next */}
           Join us our Alumni. click here to register
        </p>
        <a
          href="/register"
          className="mt-1 sm:mt-0 flex-none rounded-full bg-gray-900 px-2.5 py-1.5 text-xs sm:text-sm md:text-base font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
           >
          Register now <span aria-hidden="true">&rarr;</span>
        </a>
      </div>

      {/* Dismiss Button */}
      <div className="flex justify-end w-full sm:w-auto">
        <button type="button" className="-m-2 p-2 focus-visible:outline-offset-[-4px]" onClick={Handler}>
          <span className="sr-only">Dismiss</span>
          <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
        </button>
      </div>
    </div>
  );
}
