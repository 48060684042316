import React from 'react'
import Banner from '../component/banner/Banner'
import NavBar from '../component/navbar/NavBar'
import Footer from '../component/footer/Footer'
import ScrollToTop from '../component/topButtons/ScrollToTop'
import NewsletterSection from '../component/newsletterSection/NewsletterSection'
import FAQSection from '../component/FAQSection/FAQSection'
// import { Outlet } from 'react-router-dom'
// import Footer from '../component/footer/Footer'
// import NavBar from '../component/navbar/NavBar'
const Layout = ({ children, showFAQ, showNewsletter }) => {
  return (
    <>
      <NavBar />
      {children}
      {showNewsletter && <NewsletterSection />}
      {showFAQ && <FAQSection />}
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Layout
