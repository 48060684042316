import React from 'react';
import styles from './ConstructionPage.module.css';
import constructionGif from '../../assets/giphy.webp' 


const ConstructionPage = () => {
  return (

    <div>
        
        
           <div className={styles.container}>
        
        <div className={styles.animationContainer}>
          <img src={constructionGif} alt="Construction Worker Animation" className={styles.constructionImage} />
        </div>
        <div className={styles.messageContainer}>
          <h1 className={styles.title}>We’re Working Hard!</h1>
          <p className={styles.message}>Sorry for the inconvenience, this section is under construction. Please check back soon.</p>
        </div>
      </div>
    
    </div>
 
  );
};

export default ConstructionPage;
