import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// // import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { RouterProvider } from "react-router-dom";
import { AllRoutes } from "./allRoutes/AllRoutes";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);




